
const getDomainSelectors = () => {
  // application.html.haml:53 で全ページ読み込みしているので必ず取得される
  const excludedWarningSettings = document.getElementById("domains_of_no_warned_external_link").value.split(" ").filter(Boolean);
  const excludedDomains = [];
  const domainSelectors = [];

  if (excludedWarningSettings.length > 0) {
    excludedWarningSettings.forEach((domain) => excludedDomains.push(domain));
  }

  excludedDomains.push(location.hostname);
  excludedDomains.push("domzihpgmwtth.cloudfront.net");
  excludedDomains.push("d2vy0o1uhm8qx1.cloudfront.net");
  excludedDomains.push("coorum-performance.test-dl-asobica.com");
  excludedDomains.push("coorum-stg.test-dl-asobica.com");
  excludedDomains.push("community.dl-asobica.com");

  for (const domain of excludedDomains) {
    domainSelectors.push(`[href*="${domain}"]`);
  }

  return domainSelectors;
};

export const initExternalLinkModal = () => {
  document.addEventListener("DOMContentLoaded", () => {
    bindExternalLinkModalEvent(document);
    $("#external_link, #external_link_btn").on("click", () => {
      $.modal.close();
    });
  });
};

export const bindExternalLinkModalEvent = (scopeEl) => {
  const domainSelectors = getDomainSelectors();
  const externalLinks = $(scopeEl).find("a[href^=http]").not(domainSelectors.join(","));
  externalLinks.each(function () {
    const self = $(this);
    const found = [
      ".block-text-comp",
      ".block-image-comp",
      ".block-rich-text-comp",
      ".c-anchor-banner",
      ".st-Nav_Item",
      ".p-card",
      ".p-category--timeline",
      ".st-GroupedNav_Item",
      ".st-NavFooter_Item",
      ".mypage-Article"
    ].find((className) => self.parents(className).length > 0);
    if (found !== undefined) return true;

    $(this).on("click", function (e) {
      e.preventDefault();
      const url = $(this).prop("href");
      $("#external_link").prop("href", url);
      $("#external_link").text(url);
      $("#external_link_btn").prop("href", url);
      $("#external_link_modal").modal();
    });
  });
};
